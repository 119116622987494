export default class Tabs {

    constructor( container, options = {} ){

        if( typeof container.custom_tabs !== 'undefined' ) return;

        this.container = container;
        this.options = options;

        this.animation = options.animation ?? 'fade-in-bottom-short 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';

        this.init();
        this.container.custom_tabs = this;
    }

    init(){

        let headings_container = this.options.headings_container ?? this.container.querySelector('.tab_headings');
        if( typeof headings_container === 'string' ) {
            headings_container = this.container.querySelector(headings_container);
        }

        let contents_container = this.options.contents_container ?? this.container.querySelector('.tab_contents');
        if( typeof contents_container === 'string' ) {
            contents_container = this.container.querySelector(contents_container);
        }

        this.headings = headings_container.children;
        this.contents = contents_container.children;

        this.heading_active = this.headings[0];
        this.content_active = this.contents[0];
        
        for( let i = 0; i < this.headings.length; i++ ) {

            let heading = this.headings[i];
            heading.tab_content = this.contents[i];

            heading.addEventListener('click', ()=>{
                this.activate(heading);
            })

        }
    }

    activate(heading){

        if( this.heading_active == heading ) return;

        if( this.heading_active ) {
            this.heading_active.classList.remove('active');
        }

        if( this.content_active ) {
            this.content_active.style.display = 'none';
            this.content_active.style.animation = '';
            this.content_active.classList.remove('active');
        }

        this.heading_active = heading;
        this.content_active = heading.tab_content;

        this.heading_active.classList.add('active');
        this.content_active.style.display = '';
        this.content_active.style.animation = this.animation;
        this.content_active.classList.add('active');
    }
    
}