// console.log('add_to_cart.js');
import { ajax } from 'src/api/ajax';

const minimum_time = 500;

const cart_element = document.querySelector('.cart_count');

export function add_to_cart_ajax_init(container = document){

    container.querySelectorAll('.add_to_cart_ajax').forEach(button=>{
        if( typeof button.add_to_cart_ajax_init !== 'undefined' ) return;
        button.add_to_cart_ajax_init = 1;
        button.addEventListener('click', add_to_cart);
    })
}

function add_to_cart(e){
    
    const button = e.target;

    if( button.classList.contains('default') ) {
        // default functionality, link
        return;
    }

    e.preventDefault();

    const product_id = button.dataset.product_id;
    if( !product_id ) return;
    if( button.classList.contains('loading') ) return;
    if( button.classList.contains('not_available') ) return;

    button_init(button);
    loading(button);
    button.time_start = new Date();
    ajax('add_to_cart', { product_id }, { done: (res)=>after_request(res, button) });
}

function loading(button){
    button.textContent = 'Adding...';
    button.classList.add('loading');
}

function button_init(button){
    if( typeof button.add_to_cart_init !== 'undefined' ) return;
    button.add_to_cart_init = true;
    button.initial_text = button.textContent;
    button.style.minWidth = button.offsetWidth+'px';
}

async function after_request(res, button){

    // add minimum time to simulate loading
    let request_time = new Date() - button.time_start;
    if( request_time < minimum_time ) {
        await new Promise(resolve=>setTimeout(()=>resolve(), minimum_time-request_time))
    }
    
    if( res.success ) {
        on_success(button);
    }
    else {
        on_fail(button);
    }

    button.classList.remove('loading');
}

function on_success(button){
    button.classList.add('default');
    button.textContent = 'View cart';
    update_cart_count();
}

function on_fail(button){
    button.classList.add('not_available');
    button.textContent = 'Not available';
}

function update_cart_count(){
    if( !cart_element ) return;
    const cart_count_element = cart_element.querySelector('.count');
    if( !cart_count_element ) return;
    let count = parseInt(cart_element.dataset.count);
    count++;
    cart_count_element.textContent = count;
    cart_element.dataset.count = count;
}