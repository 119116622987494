import './internals.scss';
import Tabs from 'src/classes/Tabs';
import { on_ready } from 'src/js/utils';
import { add_to_cart_ajax_init } from 'src/woocommerce/js/add_to_cart';
// import responsive_tabs from '../../components/tabs/responsive-tabs';

console.log('internals.js');

on_ready(()=>{
    product_tabs_init();
    add_to_cart_ajax_init();
    // responsive_tabs();
});

function product_tabs_init(){
    document.querySelectorAll('.custom_tabs').forEach(el=> new Tabs(el));
}